import React from "react";
import "../styles/AboutSection.scss";
const AboutSection = () => {

  
  return (
    <div className="about"  id="about">
       

      <div className="about-container">
        <div className="about-title">Who we are?</div>
        <div className="about-text">
          We continuously strive to deliver world-class service and software
          that generate realized tangible benefits for our customers. Our value
          proposition provides substantial cost justification to our clients by
          lowering their operational costs, sharpening.
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
