const services = [

    {
        "extraStlye": true,
        "color": "#ffffff",
        "parentBgColor": "",
        "bgColor": "#6cf3cd82",
        "title":"Supply Chain",
        "content":"Once your team has designed and sourced your product, we work closely with your factories, issuing production orders, monitoring production milestones and ensuring booking requests are made. We’ll then work with your nominated freight forwarders and contracted vessel carriers to ensure the optimal sailing options are selected based on both cost and transit time. Once the vessels sets sail, our traffic team tracks vessel ETA daily, and upon berthing monitors arrival milestones such as customs clearance, freight release and container availability. We then work with your drayage company and warehouse to ensure appointments are made, container pickup, delivery , unload and empty returned.We process retail orders, whether they are sent via EDI, submitted online or sent via paper. Once orders are entered we ensure they are credit approved by your factor or credit agency. When the order is ready to ship, we provide warehouses with compliant shipping documentation, and follow up to ensure the order is routed, the appointment is made and product is shipped. Closing the loop on the order, we send advance ship notices to retailers that require them and invoice the customer through EDI, email or paper."
    },
    {
        "extraStlye": true,
        "color": "#ffffff",
        "parentBgColor": "#2A3C3B",
        "bgColor": "",
        "title":"Financial",
        "content":"As your partner, we become your accounting department. We assign invoices to your factor, collect non-factored A/R, and research, classify and dispute chargebacks. We maintain your payables. We reconcile bank and factor statements, prepare royalty reports and sales commissions. We deliver timely financial reporting including monthly P&L and balance sheets along with analysis. We oversee your bank and licensor audits. Margin reporting is critical to understand what areas of the business are most profitable. We enter all COGS invoices to actualize landed costs, and provide margin reporting by over 15 categroies including customer, brand, commodity, salesrep to name a few."
    },
    {
        "extraStlye": false,
        "color": "#ffffff",
        "parentBgColor": "#0D1B3B",
        "bgColor": "",
        "title":"Technology",
        "content":"We take state of the art technology very seriously. Over 20 full time developers are continuously enhancing our technology offering. We collaborate with our clients, refining their ideas to creating new deliverables for all. All clients have unlimited access to ENVISION, our Tier-I ERP computer system. In addition our clients have access many portals that help production, sales and executive analysis. Many advanced features such as the ability to create real-time line sheets for buyers with available to sell information are provided. VISIBILITY is our business intelligence tool which provides analytics on inventory, selling and profitability as well as POS sell-throughs of a product at retail. ENVISION MOBILE is our iPhone app that provides available to sell with images and up-to-the-minute customer order status updates."
    }
  
]

export default services;


