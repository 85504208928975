import React from "react";
import "../styles/Header.scss";

const Header = ({ bgImage, title, subtitle,matter}) => {
  return (
    <div
      className="header-container"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="header-content">
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {matter && <div className="matter">{matter}</div>}
      </div>
    </div>
  );
};

export default Header;
