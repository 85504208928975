import React from "react";
import "../styles/MissionVision.scss";
import Header from "../components/Header";
import PageContent2 from "../components/PageContent2";
const bg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/MissionVision.svg";
const mvOne = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv1.svg";
const mvTwo = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv2.svg";
const mvThree = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv3.svg";
const mvFour = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv4.svg";
const mvFive = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv5.svg";
const mvSix = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv6.svg";
const mvSeven = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/mv/mv7.svg";

const MissionVision = () => {
  const matter = "";
  const title = "Mission & Vision";
  const subtitle = "We cultivate world-class partnerships with our customers";

  const mv1 = {
    title:
      "We cultivate world-class partnerships with our customers; gaining their respect and loyalty by building positive, mutually beneficial long-term relationships.",
    content:
      "Envision Software Engineering | Core Force exists to service our customers. Our ability to provide the best service relies heavily upon the active participation and dedication of everyone in the company. We listen attentively to our customers to understand and respond to their challenges while anticipating their future needs.The essence of customer satisfaction is a commitment to exceptional quality that extends into every deliverable. We choose our employees and consulting partners carefully to ensure they are committed to delivering high quality products that deliver superior performance.Our fundamental goal is to build positive, long-term relationships with our customers. Relationships characterized by mutual respect, courtesy, and integrity are helpful and effective by nature. Our customers can rely on our strong commitment to provide software and services of the highest quality, value and usefulness and upon our ability to respond to their needs and concerns with urgency.",
    bgImage: mvOne,
    color: "#000000",
  };
  const mv2 = {
    title:
      "We provide world class mission critical supply chain management and financial services, using ERP and Financial software of the highest quality to provide our customers with great value.",
    content:
      "We continuously strive to deliver world-class service and software that generate realized tangible benefits for our customers. Our value proposition provides substantial cost justification to our clients by lowering their operational costs, sharpening the efficiency of their human resources, increasing revenues, maximizing profit, and facilitating effective communication with their world-wide partners.Our scalable products enable us to maximize our target market while boosting the growth of our client’s business from both a depth (transactional and sales volume) and breadth (functionality) perspective. We apply leading edge technology where and we know it will provide better functionality and value to our clients.",
    bgImage: mvTwo,
    color: "#000000",
  };
  const mv3 = {
    title:
      "We attract and nurture world-class professionals. Our employees share in the success they bring to the company. Individual contributions are encouraged and recognized. We promote personal and professional growth that facilitates employee satisfaction, commitment, and accomplishment.",
    content:
      "We are proud of the people in our organization. We build relationships based on team spirit, cooperation, and trust. The performance, attitude, and commitment of every employee is truly professional and productive. We believe the most productive and loyal employees thrive in an environment of trust and understanding. That is why every individual in our organization shares values consistent with this goal in mind.Envision Software Engineering | Core Force is committed to providing long-term employment for its people with continued opportunity for personal growth and development. In return, we expect our people to meet high standards of job performance while remaining flexible to adjustments in assignments, specifications, and work environment. We also expect them to continue to learn and apply new skills as required by their job tasks. This flexibility is particularly important in our industry where rapid technological change and intensifying worldwide competition compel us all to continually seek better ways to do our jobs.At Envision Software Engineering | Core Force, our people come first . We understand that on occasion, people face personal issues which temporarily affect their ability to perform at their best. In such circumstances, the entire team at Envision Software Engineering | Core Force works together to support that individual while they resolve their issues and return to their normal work mode.Advancement from within is based solely upon individual initiative, ability and demonstrated accomplishment. Since we promote from within whenever possible, managers concern themselves with the proper development of their people.The physical well being of our people is another important concern of Envision Software Engineering | Core Force. We encourage and support healthy lifestyles by covering a significant amount of the premiums of best in class medical and dental programs. Work/Life balance is recognized and strongly encouraged.",
    bgImage: mvThree,
    color: "#000000",
  };
  const mv4 = {
    title:
      "We foster initiative and creativity by allowing the individual great freedom of action in attaining well-defined objectives.",
    content:
      "Envision Software Engineering | Core Force’s operating policies are based upon the concept of management by objective. By this we mean that, insofar as possible, each individual at each level in the organization should make his or her own plans to achieve company objectives and goals. After receiving managerial approval, each individual should be given a wide degree of freedom to work within the limitations imposed by these plans, and by our general corporate policies. Finally, each person's performance should be judged on the basis of how well these individually established goals have been achieved.For their part, employees must take sufficient interest in their work to want to plan it, to propose new solutions to old problems, to take reasonable risks and exercise sound judgment in the performance of their jobs. Management by objective, as opposed to management by directive, offers opportunity for individual freedom and contribution; it also imposes an obligation for everyone to exercise initiative and enthusiasm. In this atmosphere it is important to recognize that cooperation between individuals often is essential to our growth and success.It is important for everyone to recognize there are some policies that must be established and maintained on a company wide basis. We welcome recommendations on these company wide policies from all levels, but we expect adherence to them at all times.",
    bgImage: mvFour,
    color: "#000000",
  };
  const mv5 = {
    title:
      "We honor our obligations to society by being an economic, intellectual and social asset to the community in which we operate.",
    content:
      "All of us should strive to improve the world in which we live. As a corporation operating within our community, we must make sure the community is better for our presence. This means identifying our interests with those of the community; it means applying the highest standards of honesty and integrity to all our relationships with individuals and groups; it means creating desirable jobs and generating tax revenues; it means maintaining offices of which the community can be proud; it means contributing talent, time and financial support to worthwhile community projects.",
    bgImage: mvFive,
    color: "#000000",
  };
  const mv6 = {
    title:
      "We conduct our business with uncompromising integrity; we have trust and respect for individuals; we focus on a high level of achievement and contribution; we achieve our common objectives through teamwork.",
    content:
      "We expect Envision Software Engineering | Core Force people to be open and honest in their dealings to earn the trust and loyalty of others. People at every level are expected to adhere to the highest standards of business ethics and must understand that anything less is unacceptable. Ethical conduct cannot be assured by written Envision Software Engineering | Core Force policies; it must be an integral part of our organization, a deeply ingrained philosophy that is passed from one employee to another.We attract highly capable, diverse, innovative people and recognize their efforts and contributions to the company. Envision Software Engineering | Core Force people contribute enthusiastically and share in the success that they make possible.All Envision Software Engineering | Core Force people must be leaders who generate enthusiasm and respond with extra effort to meet customer needs. Techniques and management practices which are effective today may be outdated in the future. For us to remain at the forefront in all our activities, people should always be looking for new and better ways to do their work.We recognize that it is only through effective cooperation within and among organizations that we can achieve our goals. Our commitment is to work as a team to fulfill the expectations of our customers who depend upon us.",
    bgImage: mvSix,
    color: "#000000",
  };
  const mv7 = {
    title:
      "We have fun here! We realize that a significant portion of our lives are spent together in the workplace environment, and this is where friendships are cultivated and maintained.",
    content:
      "Envision Software Engineering | Core Force “Activities Committee” conducts numerous events over the course of the year to bond all teams together to share personal experiences and celebrate individual achievements. We enjoy each other’s company, talk, laugh and dance together!",
    bgImage: mvSeven,
    color: "#000000",
  };

  return (
    <div className="mission-vision-container">
      <Header bgImage={bg} title={title} subtitle={subtitle} matter={matter} />

      <PageContent2
        bg={mv1.bgImage}
        title={mv1.title}
        content={mv1.content}
        color={mv1.color}
      />
      <PageContent2
        bg={mv2.bgImage}
        title={mv2.title}
        content={mv2.content}
        color={mv2.color}
      />
      <PageContent2
        bg={mv3.bgImage}
        title={mv3.title}
        content={mv3.content}
        color={mv3.color}
      />
      <PageContent2
        bg={mv4.bgImage}
        title={mv4.title}
        content={mv4.content}
        color={mv4.color}
      />
      <PageContent2
        bg={mv5.bgImage}
        title={mv5.title}
        content={mv5.content}
        color={mv5.color}
      />
      <PageContent2
        bg={mv6.bgImage}
        title={mv6.title}
        content={mv6.content}
        color={mv6.color}
      />
      <PageContent2
        bg={mv7.bgImage}
        title={mv7.title}
        content={mv7.content}
        color={mv7.color}
      />
    </div>
  );
};

export default MissionVision;
