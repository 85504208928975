import React from 'react';
import '../styles/Testimonial.scss';

const Testimonial = () => {
    // const sectionStyle = {
    //     // backgroundColor,
    //     // backgroundImage,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //   };
  return (
    <div className="testimonial" id="testimonial">
      <div className='testimonial-text'>Our team is dedicated to excellence and driven by a customer-first philosophy, striving every day to create solutions that not only support but enhance the growth of our clients' businesses. Thank you for trusting  as your partner; together, we're building stronger, more efficient enterprises for the future.        
      </div>
    </div>
  );
};


export default Testimonial