import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Home from "../pages/Home";
import AboutCompany from "../pages/AboutUs/AboutCompany"
// import Company from "./pages/Company";
import Careers from "../pages/Career";
import MissionVision from "../pages/MissionVision";
import Clients from "../pages/Clients";
import News from '../pages/News'
import Services from "../pages/Services";
// import Testimonials from "./pages/Testimonials";
// import News from "./pages/News";
// import Contact from "./pages/Contact";

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about/company" element={<AboutCompany />} />
        <Route path="/about/mission-vision" element={<MissionVision />} />
        <Route path="/about/careers" element={<Careers />} />
        <Route path="/testimonials" element={<Clients />} />
        <Route path="/news" element={<News />} />
        <Route path="/services" element={<Services />} />
       {/* 
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer/>
    </Router>
  );
};

export default AppRouter;
