const testimonials = [

    {
        "logo":"panoceanic.webp",
        "name":"Joseph Terzi",
        "title":"Owner – Pan Oceanic",
        "message":"Partnering with CoreForce has fundamentally changed my entire business. My company was operating using 30 year old technology and had we not made use of CF’s services, we would not be able to operate the company on a level that would be economically feasible in today’s times. Thanks to them, I am able to work with a lean full-time team and keep overhead costs in check."
    },
    {
        "logo":"cb.webp",
        "name":"Kenneth Tawil",
        "title":"Partner – Creative Brands",
        "message":"Jeff Clayton and the entire team at Core Force helped us elevate our business to new heights. They are an excellent back office with the latest technologies customized to our needs. Core Force allows us to focus on product sales and marketing while they monitor our supply chain, process our orders and produce our financials."
    },
    {
        "logo":"ahq.webp",
        "name":"Abe Chehebar",
        "title":"CEO Accessory Headquarters",
        "message":"Core Force effectively manages our back office, allowing us to dedicate our time to designing, sourcing and presenting our products to our retailers. I’m very impressed with their ability to present very clear financial information on a monthly basis which provides critical insight into our business."
    },
    {
        "logo":"iapparel-color.gif",
        "name":"Sammy Cotton",
        "title":"CEO – iApparel Brands",
        "message":"iApparel Brands has been with Core Force for 8 years now and it was the best move we have ever made. As far as “back office” functions, they are second to none. They build a custom process with each company based on their metrics and operations. They have the ability to cater to each individual company’s needs because they have their own computer system called Envision which is compatible to almost any business and also very user friendly. With offices in NJ and India, they basically are “on point” 24/7. All functions are performed in an efficient, meticulous and above all professional manner. Core Force is more than just a “back office”, they are a true “partner”. The owner, Jeff Clayton is hands-on and personally involved in all important aspects, especially when that “unplanned” crisis hits. His integrity, relentless work ethic and care is unmatched and his employees look to follow his lead. I cannot count the times Jeff and I were talking out strategies deep into the morning hours. It is comforting (to say the least) to know without a doubt that somebody “has your back” unconditionally. I wish Core Force continued growth and success! Thanks for all you do for us!!!"
    }
]

export default testimonials;


