import React from "react";
import "../styles/HeroSection.scss";

const HeroSection = ({ title, matter}) => {
  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero-title">
          {title}
        </div>
        <div className="hero-text">{matter}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
