import React from "react";
import "../styles/Clients.scss";
import Header from "../components/Header";

import NewsDetail  from "../components/NewsDetails";
const bg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/news.jpg";
const Clients = () => {
  const matter ="";
  const title = "Latest News";
  const subtitle = "Stay updated! See what’s new with us in the Latest News section.";

  return (
    <div className="clients-container">
      <Header bgImage={bg} title={title} subtitle={subtitle} matter={matter} />
      <NewsDetail />
    </div>
  );
};

export default Clients;
