import React, {  } from "react";
import { Carousel } from "antd";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";

const HeaderContent = ({
  title,
  matter,
  carouselData,
  currentIndex,
  setCurrentIndex,
  bgImage,
  bgColor,
}) => {



  const onChange = (currentSlide) => {
    setCurrentIndex(currentSlide); 
  };
  const currentData = carouselData ? carouselData[currentIndex] : {};
//  const currentSlide  =1 
  const headerStyle = {
    backgroundColor: carouselData ? currentData.bgColor : bgColor,
    backgroundImage: carouselData
      ? `url(${currentData.bgImage})`
      : `url(${bgImage})`,
  };





  return (
    <div className="w-header-container"  style={headerStyle}>
     <Navbar />
      {carouselData && currentData.bgVideo && (
        <video
          className="video-background"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={currentData.bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

    
      {!currentData.bgVideo && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100dvh",
            backgroundColor: carouselData
              ? currentData.bgColor
              : bgColor,
            zIndex: -1,
          }}
        ></div>
      )}

      <div
        className="w-header-container-inner"
      >
        {carouselData ? (
          <Carousel  
          autoplay
          infinite 
          afterChange={onChange}>
            {carouselData.map((data, index) => (
              <div key={index}>
                <HeroSection
                  title={data.title}
                  matter={data.matter}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <HeroSection
            title={title}
            matter={matter}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderContent;
