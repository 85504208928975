import React, { useState } from "react";
import Index from "../components/SlidePage";
import AboutSection from "../components/AboutSection";
import Testimonial from "../components/Testimonial";
import "../styles/App.scss";

const App = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const getBackgroundStyles = () => {
    const styles = [
      { color: "#283594", image: "url('https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/testimonial.svg')" },
      { color: "#003255", image: "url('https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/testimonial.svg')" },
      { color: "#283594", image: "url('https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/testimonial.svg')" },
    ];

    return styles[currentIndex] || { color: "#ffffff", image: "none" };
  };

  const backgroundStyles = getBackgroundStyles();
  return (
    <div>
      {/* <Navbar /> */}
      <Index currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      <AboutSection  />
      <Testimonial backgroundColor={backgroundStyles.color} 
        backgroundImage={backgroundStyles.image} />
    </div>
  );
};

export default App;
