import React from "react";
import SliderContent from "../ui/Slider";
import "../styles/slider.scss";
// import slide1 from "../assets/backgrounds/bg-1.svg";
// const slide2=  "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/bg-2.svg";
// const slide3 = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/bg-1.svg";
const videoI = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/videos/video.mp4"

const Index = ({ currentIndex, setCurrentIndex }) => {
  const title = "Complete Supply Chain Management & Financial Services";
  const matter =
    "Focus on your core competency of Designing, Sourcing, and Selling. Leave everything else to us";
  const bgImage = "";
  const bgColor = "#2A3191";

  const carouselData = [
    {
      title: "Complete Supply Chain Management & Financial Services",
      matter:
        "Focus on your core competency of Designing, Sourcing, and Selling. Leave everything else to us",
      bgImage: "",
      bgVideo: videoI,
      bgColor: "#2A3191",
    },
    {
      title: "We help Growing businesses",
      matter:
        "Our team of industry professionals take care of your day to day business needs allowing you to focus on building brands, designing a compelling product, efficient sourcing and unparalleled sales growth",
      bgVideo: videoI,
      bgColor: "#313D82",
    }
  ];

  return (
    <div className="container">
      <SliderContent
        title={title}
        matter={matter}
        bgImage={bgImage}
        bgColor={bgColor}
        carouselData={carouselData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
};

export default Index;
