import React from "react";
import "../styles/news-details.scss";
import news from '../api/news-data.js'


const NewsDetail = ({ bgImage, title, subtitle,matter}) => {
  return (
    <div className="news-container">
        
        {news.map((info) => (
          <div class="news-card">     
            <div class="news-card">
                <div class="news-logo-column">
                <img 
                    src={(`https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/news/${info.logo}`)}
                    width="124px"
                    alt={info.src} />
                </div>
                <div class="news-message-column">
                    <div class="name">{info.name}</div>
                    <div class="title">{info.title}</div>
                    <p>{info.message}</p>
                </div>
            </div>
            <div style={{ margin: '10px' }}>&nbsp;</div>
          </div>  
          
            
        ))}
    </div>
  );
};

export default NewsDetail;
