import React from "react";
import "../styles/Career.scss";
import Header from "../components/Header";
const bg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/Career.svg";
// const jobsBg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/job-bg.png";

const Career = () => {
    const matter = 'A focussed effort and perseverance towards achieving greatness comes with an attitude to learn and win. At Envision, we prioritise attitude over the skills and the combined growth is inevitable.'
    const title = 'Careers'
    const subtitle = 'Success isn’t really that difficult'

    const jobs =  [
        {
          title: "Process Associate (Suply Chain Managment)",
          description: "",
        },
        {
          title: "Data Analyst (ERP Consultant)",
          description: "",
        },
        {
          title: "EDI Implementation Specialist",
          description: "",
        },
        {
          title: "Accounts Receivable",
          description: "",
        },
        {
          title: "Accounts Payable",
          description: "",
        },
        {
          title: "Accounts Payable",
          description: "",
        },
      ]
  return (
    <div className="career-container">
        <Header
        bgImage={bg}
        title={title}
        subtitle={subtitle}
        matter={matter}
      />
      <div class="job-posting">
      <div className="title2"><small>Current Career Opportunities at</small> <br/> Envision Software Engineering Private Limited</div>
      <div className="job-list">
        {jobs.map((job, index) => (
          <div key={index} className="job-card">
            <div className="job-title">{job.title}</div>
            <div className="job-description">{job.description}</div>
          </div>
        ))}
        <p class="message">These are the current openings as if for now 
        Please send us an email to <b>mail ID : Recruiting.Envision@coreforce.com</b> if you think you are suitable for the position</p>
      </div>
      
      </div>
    </div>
  );
};

export default Career;
