import React from "react";
import "../../styles/AboutCompany.scss";
import Header from "../../components/Header";
import PageCard from "../../components/PageCard";
import PageContent from "../../components/PageContent";
import PageContent2 from "../../components/PageContent2";
const bg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/AboutUs.svg";
const ourGrowthImage = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/ourGrowth.svg";
const ourValueI = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/ourValues.svg";
// const ourPurposeI = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/ourPurpose.svg";
const ourCommitmentImage = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/ourCommitment.svg";

const AboutUs = () => {
  const matter =
    "Envision Software Engineering was founded in 2006, as a wholly owned subsidiary of Information Solutions Inc, a US-based IT Software, Supply Chain Management, and Financial Services company.";
  const title = "About Us";
  const subtitle = "Envision Software Engineering";

  const ourGrowth = {
    title: "OUR GROWTH",
    content:
      "Established as a 4-developer IT team based out of Chennai, ESE has grown to over 300 employees, with its office now located in Coimbatore. Branded under the registered name of Core Force as well, the company supports its clients in the US, managing over $1.5B USD of imports from around the world and providing financial services.",
    bgImage: ourGrowthImage,
    color: "#121212",
    bgColor: "#FFFFFF",
    parentBgColor: "#022965",
    parentBgImage: "",
  };
  const ourValues = {
    title: "OUR VALUES",
    content:
      "Envision Software Engineering | Core Force exists to service our customers. Our ability to provide the best service relies heavily upon the active participation and dedication of everyone in the company. We listen attentively to our customers to understand and respond to their challenges while anticipating their future needs.",
    bgImage: ourValueI,
    color: "#fff",
  };


  const ourPurpose = {
    title: "OUR PURPOSE",
    content:
    "We are proud of the people in our organization. We build relationships based on team spirit, cooperation, and trust. The performance, attitude, and commitment of every employee are truly professional and productive. We believe the most productive and loyal employees thrive in an environment of trust and understanding. That is why every individual in our organization shares values consistent with this goal in mind.",
    bgImage: "",
    color: "#121212",
    parentBgColor: "#0eddcc",
    parentBgImage: "",
    bgColor: "#f7eded",
  };

  const ourCommitment = {
    title: "Our Commitment",
    content:"We continuously strive to deliver world-class service and software that generate realized tangible benefits for our customers. Our value proposition provides substantial cost justification to our clients by lowering their operational costs, sharpening the efficiency of their human resources, increasing revenues, maximizing profit, and facilitating effective communication with their worldwide partners.",
    bgImage: ourCommitmentImage,
    color: "#121212",
  };
  return (
    <div className="about-us-container">
      <Header bgImage={bg} title={title} subtitle={subtitle} matter={matter} />
      <PageCard
        bg={ourGrowth.bgImage}
        bgColor={ourGrowth.bgColor}
        parentBgColor={ourGrowth.parentBgColor}
        parentBgImage={ourGrowth.parentBgImage}
        title={ourGrowth.title}
        content={ourGrowth.content}
        color={ourGrowth.color}
      />

      <PageContent
        bg={ourValues.bgImage}
        title={ourValues.title}
        content={ourValues.content}
        color={ourValues.color}
      />
      <PageContent
        bg={ourPurpose.bgImage}
        title={ourPurpose.title}
        content={ourPurpose.content}
        color={ourPurpose.color}
      />

     

      <PageContent2
        bg={ourCommitment.bgImage}
        title={ourCommitment.title}
        content={ourCommitment.content}
        color={ourCommitment.color}
      />
    </div>
  );
};

export default AboutUs;
