import React from 'react';
import '../styles/Footer.scss';


const Logo = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/logos/cf-logo1.svg";
const fb= 'https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/icons/fb.svg'
const ind  ='https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/icons/ind.svg'
const insta = 'https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/icons/insta.svg'
const x = 'https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/icons/x.svg'
const yt = 'https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/icons/yt.svg'


const Footer = () => {
  return (
    <footer className="footer" id='footer'>
      <div className="footer-container">
      <div className="footer-top">
        <div className="logo-placeholder">
        <img src={Logo} alt="company logo" />
        <div className="company-name">Envision Software Engineering Pvt Ltd</div>
      </div>
      <div className="footer-social">
         {/*  <a href="https://facebook.com" aria-label="Facebook" className="social-icon"><img src={fb} alt="fb" /></a>
          */} <a href="https://www.linkedin.com/company/envision-software-engineering-pvt-ltd/" aria-label="LinkedIn" className="social-icon"><img src={ind} alt="ind" /></a>
          <a href="https://x.com" aria-label="X" className="social-icon"><img src={insta} alt="insta" /></a>
        {/*   <a href="https://instagram.com" aria-label="Instagram" className="social-icon"><img src={x} alt="x" /></a>
          <a href="https://youtube.com" aria-label="YouTube" className="social-icon"><img src={yt} alt="yt" /></a> */}
        </div>
        </div>


        <div className="footer-content">
          <div className="footer-section">
            <div>
            <div className='footer-title'>Our Offices</div>
            <div className='sub-container'>
            <div className='footer-text'>
              1090 King Georges Post Rd <br />
              Suite 1101 <br />
              Edison, NJ, 08837 <br />
              Phone: 732-346-2500
            </div>
            <div className='footer-text'>
              Module No. 005/2 & 005/1A, Ground Floor <br />
              Tidel Park, Vilankurichi Road, <br />
              Aerodrome Post <br />
              Coimbatore - 641 014
            </div>
            </div>
            </div>
           
          </div>
          <div className="footer-section links">
            <div>
            <div className='footer-title'>Company</div>
            <ul>
              <li><a className='footer-text' href="/about">About Us</a></li>
              <li><a className='footer-text' href="/careers">Careers</a></li>
              <li><a className='footer-text' href="/csr">CSR</a></li>
              <li><a className='footer-text' href="/media-news">Media & News</a></li>
            </ul>
          </div>
          <div >
            <div className='footer-title'>Resources</div>
            <ul>
              <li><a className='footer-text' href="/clients">Our Clients</a></li>
              <li><a className='footer-text' href="/services">Our Service</a></li>
              <li><a className='footer-text' href="/team">Our Team</a></li>
            </ul>
          </div>
          </div>
        </div>
  
      </div>
      <div className="footer-bottom">
        <div className='footer-text'>&copy; 2024 Envision Software Engineering Pvt Ltd. All Rights Reserved.</div>
      </div>
    </footer>
  );
};

export default Footer;
