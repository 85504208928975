import React from "react";
import "../styles/PageContent.scss";

const PageContent = ({bg,title,content,color}) => {
    const style = {
        backgroundImage: `url(${bg})`,
        color:color,
      };
  return (
    <div className="main-container" style={style}>
        <div className="child-container">
          <div className="title">{title}</div>
          <div className="content">{content}
          </div>
        </div>
    </div>
  );
};

export default PageContent;
