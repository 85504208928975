import React from "react";
import "../styles/testimonial-details.scss";
import testimonials from '../api/testimonial-data.js'


const TestimonialDetail = ({ bgImage, title, subtitle,matter}) => {
  return (
    <div className="testimonial-container">
        <div class="title2">
            Our Testimonials
        </div>
        {testimonials.map((testimonial) => (
          <div class="testimonial-card">     
            <div class="testimonial-card">
                <div class="logo-column">
                <img 
                    src={`https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/testimonials/${testimonial.logo}`}
                    width="124px"
                    alt={testimonial.src} />
                </div>
                <div class="message-column">
                    <div class="name">{testimonial.name}</div>
                    <div class="title">{testimonial.title}</div>
                    <p>{testimonial.message}</p>
                </div>
            </div>
            <div style={{ margin: '10px' }}>&nbsp;</div>
          </div>  
          
            
        ))}
    </div>
  );
};

export default TestimonialDetail;
