import React, { useState,useEffect} from "react";
import "../styles/Navbar.scss";
import { Button } from "antd";
//import Logo from "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/logos/cf-logo1.svg";
import { Link,useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); 
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = ()=>{
    setIsOpen(!isOpen)
    setIsAboutOpen(!isAboutOpen)
  }

  
  const [isFixed, setIsFixed] = useState(false);

  const fixedToTopStyle = {
    background: "rgba(23, 127, 200, 0.15)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(12.7px)",
    WebkitBackdropFilter: "blur(12.7px)",
  };

  const handleContactUsClick = () => {
    navigate("/"); 
    setTimeout(() => {
      const footer = document.getElementById("footer");
      if (footer) {
        footer.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); 
  };

  return (
    <nav className="navbar" style={isFixed ? fixedToTopStyle : {}} >
      <div className="logo-placeholder">
        <div className="company-name">
          Envision Software Engineering Pvt Ltd
        </div>
      </div>

      <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        ☰
      </button>

      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <li>
          <Link onClick={() => setIsOpen(!isOpen)} to="/">Home</Link>
        </li>
        <li>
          <button
            className="dropdown-toggle"
            onClick={() => setIsAboutOpen(!isAboutOpen)}
          >
            About Us
          </button>
          {isAboutOpen && (
            <ul className="dropdown-menu">
              <li>
                <Link onClick={toggleMenu} to="about/company">Company</Link>
              </li>
              <li>
                <Link onClick={toggleMenu} to="about/careers">Careers</Link>
              </li>
              <li>
                <Link onClick={toggleMenu} to="about/mission-vision">Mission & Vision</Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link onClick={() => setIsOpen(!isOpen)} to="services">Services</Link>
        </li>
        <li>
          <Link onClick={() => setIsOpen(!isOpen)} to="testimonials">Testimonials</Link>
        </li>
        <li>
          <Link onClick={() => setIsOpen(!isOpen)} to="news">News</Link>
        </li>
        <li>
          {/* <Link onClick={() => setIsOpen(!isOpen)} to="/">Contact Us</Link> */}

          <Button onClick={handleContactUsClick} type="text">
            Contact Us
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
