import React from "react";
// import "../styles/Services.scss";
import Header from "../components/Header";
import services from "../api/services-data";
import PageCard from "../components/PageCard";
const bg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/services/services.svg";
const sr1 = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/services/sr1.svg";
const sr2 = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/services/sr2.svg";
const sr3 = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/services/sr3.svg";
const Services = () => {
  const matter = "";
  const title = "Our Services";
  const subtitle =
    "Optimizing Operations, Enhancing Efficiency: Core BPO Solutions for Every Industry";

    const parentBgImages = [sr1, null, null]; 
  const bgImages = [null, sr2, sr3]; 

  const updatedServices = services.map((service, index) => ({
    ...service,
    parentBgImage: parentBgImages[index] || service.parentBgImage, 
    bgImage: bgImages[index] || service.bgImage, 
  }));

  return (
    <div className="career-container">
      <Header bgImage={bg} title={title} subtitle={subtitle} matter={matter} />
      {/* <div className="job-list"> */}
        {updatedServices.map((service, index) => (
          <PageCard
            bg={service.bgImage}
            extraPading={true}
            extraStlye={service.extraStlye}
            bgColor={service.bgColor}
            parentBgColor={service.parentBgColor}
            parentBgImage={service.parentBgImage}
            title={service.title}
            content={service.content}
            color={service.color}
          />
        ))}
      </div>
    // </div>
  );
};

export default Services;
