import React from "react";
import "../styles/Clients.scss";
import Header from "../components/Header";
import TestimonialDetail  from "../components/Testimonials-details";
const bg = "https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/clients.svg";

const Clients = () => {
  const matter ="";
  const title = "Our Clients";
  const subtitle = "Our Partners in Success";

  return (
    <div className="clients-container">
      <Header bgImage={bg} title={title} subtitle={subtitle} matter={matter} />
      <TestimonialDetail />
    </div>
  );
};

export default Clients;
