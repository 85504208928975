import React from "react";
import "../styles/PageCard.scss";
const PageCard = ({
  bg,
  bgColor,
  extraPading,
  parentBgColor,
  extraStlye,
  parentBgImage,
  title,
  content,
  color,
}) => {
  const parentStyle = {
    backgroundColor: parentBgColor ? parentBgColor : '',
    backgroundImage: parentBgImage
      ? `url(${parentBgImage})`  
      : '',
    color: color,
  };

  console.log(title ,'what is coming')
  const wrapper = {
    backgroundColor: bgColor,
    borderRadius:extraStlye?'10px':''
  };
  const childStyle = {
    backgroundImage: `url(${bg})`,
    padding:extraPading?'5rem 5rem':'5rem 2rem'
  };
  return (
    <div className="main-container" style={parentStyle}>
      <div className="child-container-wrapper" style={wrapper}>
        <div className="child-container" style={childStyle}>
          <div className="cardTitle">{title}</div>
          <div className="cardContent">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default PageCard;
