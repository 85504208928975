const news = [

    {
        "logo":"coco-brands.webp",
        "name":"Coco Brands ",
        "title":"Partnering with CoCo Brands for a brighter future",
        "message":"We are pleased to announce our new partnership with CoCo Brands, a pioneer in the importer of apparel sector. This collaboration marks a significant step forward as we combine our expertise in Supply Chain Management and Financial Services with their innovative approach. Together, we aim to accelerate sales and amplify profitability, fueled by our shared commitment to deliver customer-centric solutions that elevate the apparel experience. Welcome aboard, Coco Brands—let’s make waves and achieve great things together!"
    },
    {
        "logo":"lfw.webp",
        "name":"Lifeworks Technology",
        "title":"Welcome Lifeworks Technolgy",
        "message":"We’re excited to announce the addition of Lifeworks Technology to our esteemed customer base! As a premier importer of global designer and manufacturer of consumer products, Lifeworks Technolgy's dedication to innovation and excellence aligns perfectly with our vision. We look forward to propelling their success to new heights through our Supply Chain Management and Financial Services. Stay tuned for exciting updates on this dynamic collaboration!"
    },
    {
        "logo":"silverpoint.webp",
        "name":"Silverpoint Technologies",
        "title":"Welcoming Silverpoint to our Community",
        "message":"We are thrilled to announce that Silverpoint,  a leading innovator in  floor care products industry, has selected Core Force to fuel their growth. Renowned for their commitment to crafting high-quality, sophisticated floor care solutions that make cleaning easier and more efficient for customers. Silverpoint is poised to benefit from our Supply Chain Management and Financial Services. We’re excited to see the remarkable results of this powerful partnership and the extraordinary successes it will bring!"
    },
    {
        "logo":"brick-craft.webp",
        "name":"BrickCraft",
        "title":"A New Journey with BrickCraft!",
        "message":"Today marks the beginning of an exciting journey with Brickcraft, a trailblazer in the brick products industry. Their exceptional brick sets and accessories, combined with our expertise in Supply Chain Management and Financial Services, promises to be a powerful catalyst for their success. We eagerly anticipate the milestones we will achieve together and are thrilled to welcome Brickcraft to this promising partnership. Here's to a future filled with growth and remarkable achievements!"
    },
    



    {
        "logo":"indecord.webp",
        "name":"Indecor Home ",
        "title":"Welcome Indecor home to our community!",
        "message":"We are proud to unveil our new partnership with Indecor Home, a trailblazer in the home goods industry. Combining their innovative products with our strategic expertise in Supply Chain Management and Financial Services, we are set to embark on a transformative journey. Together, we will drive innovation and achieve remarkable results. Welcome to this thrilling new chapter, Indecor Home!"
    },
    {
        "logo":"cutie-pie.webp",
        "name":"Cutie Pie Baby",
        "title":"Introducing Cutie Pie Baby!",
        "message":"We’re excited to introduce Cutie pie baby as our new partner! Known for their excellence in the baby apparel industry, they’re the perfect match for our capabilities in Supply Chain Management and Financial Services. We’re looking forward to working together and achieving great things. Welcome aboard, Cutie pie baby —let’s make this partnership a success!"
    }
]







export default  news;


